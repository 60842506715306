import React from 'react'
import ArticleProductCard from '../uiComponents/articleProductCard'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import 'swiper/swiper.min.css'

// install Swiper components
SwiperCore.use([Navigation])

const Wrapper = styled.div`
  .swiper-slide {
    overflow: initial;
    @media (min-width: ${desktopBreakpoint}) {
      max-width: 262px;
      width: 262px;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    h2 {
      text-align: center;
      text-transform: lowercase;
    }
  }
`

const Title = styled.h2`
  font-family: ${font.ben};
  font-size: ${mobileVW(30)};
  color: ${colors.brownDarkest};
  margin-bottom: ${mobileVW(32)};
  text-transform: lowercase;
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 40px;
    color: ${colors.brownDarkest};
    margin-bottom: 56px;
  }
`

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 95%,
    rgba(255, 255, 255, 0.8) 99%,
    rgba(255, 255, 255, 1) 100%
  );
  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`

import {
  mobileVW,
  newDesktopVW,
  font,
  colors,
  desktopBreakpoint,
} from '../../../styles'

const ArticleProductSwiper = ({
  content,
  isOrder,
  centerSlides,
  mini,
  overlay,
  slidesMobile,
  slidesDesktop,
  disableSwiping,
}) => {
  const { title, slideCategories } = content

  return (
    <>
      <Wrapper>
        <Title>{title}</Title>
        <Swiper
          slidesPerView={slidesMobile ? slidesMobile : 2.1}
          observeParents
          observer
          observeSlideChildren
          // navigation
          // initialSlide={centerSlides ? 1 : 0}
          // centeredSlides={centerSlides ? true : false}
          breakpoints={{
            1024: {
              initialSlide: 0,
              spaceBetween: 40,
              slidesPerView: slidesDesktop ? slidesDesktop : 4,
              loop: false,
              initialSlide: centerSlides ? 1 : 0,
              centeredSlides: centerSlides ? true : false,
              allowTouchMove: disableSwiping ? false : true,
            },
          }}
        >
          {slideCategories
            ? slideCategories.map((category, i) => (
                <SwiperSlide key={i}>
                  <ArticleProductCard
                    content={category}
                    isOrder={isOrder}
                    mini={mini}
                  />
                </SwiperSlide>
              ))
            : null}
          {overlay && <Overlay />}
        </Swiper>
      </Wrapper>
    </>
  )
}

export default ArticleProductSwiper
